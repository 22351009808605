import {
  GET_PDF_MENU,
  PDF_MENU_RECEIVED,
  EMPTY_PDF_MENU,
} from "../actions/types";

const initialState = {
  pdf_menu_data: null,
};

const pdfMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PDF_MENU:
      return { ...state };
    case EMPTY_PDF_MENU:
      return { ...state, pdf_menu_data: null };
    case PDF_MENU_RECEIVED:
      return { ...state, pdf_menu_data: action.json };
    default:
      return state;
  }
};
export default pdfMenuReducer;
