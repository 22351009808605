import { put, delay, takeLatest } from "redux-saga/effects";
import { showNotification, hideNotification } from "../actions";

const base_url = process?.env?.REACT_APP_API_URL;
let myHeaders = new Headers({
  "Content-Type": "application/json",
  Accept: "application/json",
  // "Access-Control-Allow-Origin": "*",
});
const defaultErrorMsg = "Something went wrong! try again later.";
let nextNotificationId = 0;
function* showNotificationWithTimeout(action) {
  const id = nextNotificationId++;
  yield put(showNotification(id, action.text, action.msgtype));
  yield delay(5000);
  yield put(hideNotification(id));
}

function* emptyToastMsg() {
  yield put({ type: "EMPTY_TOAST_MSG" });
}

export default function* toastActionWatcher() {
  yield takeLatest("EMPTY_TOAST_MSG", emptyToastMsg);
  yield takeLatest(
    "SHOW_NOTIFICATION_WITH_TIMEOUT",
    showNotificationWithTimeout
  );
}
