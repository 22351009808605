import { put, takeLatest } from "redux-saga/effects";
import {
  GET_PDF_MENU,
  PDF_MENU_RECEIVED,
  LOADING_STATUS,
} from "../actions/types";

const defaultErrorMsg = "Something went wrong! try again later.";
const base_url = process?.env?.REACT_APP_API_URL;
let myHeaders = new Headers({
  "Content-Type": "application/json",
  Accept: "application/json",
  // "Access-Control-Allow-Origin": "*",
});
function* fetchPdfMenu(action) {
  console.log("base_url", base_url);
  yield put({ type: LOADING_STATUS, status: true });
  const json = yield fetch(
    `${base_url}/qr_code_apis/get_pdf_details/${action.id}`,
    {
      mode: "cors",
      headers: myHeaders,
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("fetchPdfMenu failed : " + error.message));
  yield put({ type: LOADING_STATUS, status: false });
  if (json?.status && json?.data) {
    yield put({ type: PDF_MENU_RECEIVED, json: json?.data });
  } else {
    console.log(
      `fetchPdfMenu error : ${json?.message ? json.message : defaultErrorMsg}`
    );
  }
}

export default function* pdfActionWatcher() {
  yield takeLatest(GET_PDF_MENU, fetchPdfMenu);
}
