import React, { Suspense } from "react";

// Components

// Screens

// CSS
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CSS/style.css";
import "./CSS/responsive.css";

import Main from "./Routing";

function App() {
  return <Main />;
}

export default App;
