import React, { useState, useCallback, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { imageExists } from "../../helpers";
import { NoPreviewAvailable } from "../../Assets";
const ImageSlider = ({ images, onOpen }) => {
  const imageArr = images ?? [];
  return (
    <Carousel
      showThumbs={false}
      showIndicators={false}
      showArrows={false}
      showStatus={false}
      autoPlay={true}
      onClickItem={(index, item) => {
        console.log("itemitem", index, item);
        onOpen(index);
      }}
    >
      {imageArr.map((image, index) => {
        return (
          <div>
            <img alt="" src={image} />
          </div>
        );
      })}
    </Carousel>
  );
};
export default ImageSlider;
