import React from "react";
import { bigLogo } from "../../Assets";

const UnableToLoadData = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="not-found-menu">
            <figure>
              <img src={bigLogo} />
            </figure>
            <h1>Menu Not Available</h1>
            <div className="error-details">
              The menu you are trying to access is longer available. Please ask
              the restaurant to provide you with the latest QR code to scan.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnableToLoadData;
