import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getPdfMenu, emptyPdfMenu } from "../../Redux/actions";
import PDFViewer from "pdf-viewer-reactjs";
import UnableToLoadData from "../Components/UnableToLoadData";
import PleaseWait from "../Components/PleaseWait";
import samplePDF from "../../Assets/sample123.pdf";
import { connect } from "react-redux";

let PdfViewer = ({ getPdfMenu, pdf_menu_data, loading, emptyPdfMenu }) => {
  let { id } = useParams();
  console.log("id:", id);
  console.log("loading:", loading);
  console.log("pdf_menu_data:", pdf_menu_data?.qr_code_id);
  useEffect(() => {
    if (
      loading === false &&
      (pdf_menu_data === null || pdf_menu_data === undefined)
    ) {
      getPdfMenu(id);
    } else if (
      loading === false &&
      pdf_menu_data?.qr_code_id &&
      pdf_menu_data?.qr_code_id !== id
    ) {
      emptyPdfMenu();
    }
  }, []);

  function getPdfPage() {
    if (pdf_menu_data?.pdf_file_path) {
      return (
        <PDFViewer
          document={{
            url: pdf_menu_data.pdf_file_path,
          }}
          alert={(err) => {
            console.log("PDF Loading error " + err?.message);
            return <UnableToLoadData />;
          }}
        />
      );
    } else if (loading === false) {
      return <UnableToLoadData />;
    } else {
      return <PleaseWait />;
    }
  }

  return <div className="wrapper pdf_container">{getPdfPage()}</div>;
};
const mapStateToProps = (state) => ({
  pdf_menu_data: state.pdfMenuReducer.pdf_menu_data,
  loading: state.loaderReducer.loading,
});

const mapDispatchToProps = {
  getPdfMenu: getPdfMenu,
  emptyPdfMenu: emptyPdfMenu,
};
PdfViewer = connect(mapStateToProps, mapDispatchToProps)(PdfViewer);
export default PdfViewer;
