import { all, fork } from "redux-saga/effects";
import tableDetailActionWatcher from "./table_detail";
import pdfActionWatcher from "./pdf_menu";
import authDetailActionWatcher from "./auth_detail";
import cartDetailActionWatcher from "./cart_detail";
import toastActionWatcher from "./toast";

export default function* rootSaga() {
  yield all([
    fork(tableDetailActionWatcher),
    fork(pdfActionWatcher),
    fork(toastActionWatcher),
    fork(authDetailActionWatcher),
    fork(cartDetailActionWatcher),
  ]);
}
