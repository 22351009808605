import { combineReducers } from "redux";
import tableDetailReducer from "./table_detail";
import pdfMenuReducer from "./pdf_menu";
import toastReducer from "./toast";
import authDetailReducer from "./auth_detail";
import cartDetailReducer from "./cart_detail";
import loaderReducer from "./loader";
const appReducer = combineReducers({
  tableDetailReducer,
  loaderReducer,
  pdfMenuReducer,
  toastReducer,
  authDetailReducer,
  cartDetailReducer,
});

const rootReducer = (state, action) => {
  // if (action.type === LOGOUT_SUCCESS_ACTION) {
  //   state = undefined;
  // }
  return appReducer(state, action);
};
export default rootReducer;
