import { TOAST_MSG, EMPTY_TOAST_MSG, HIDE_TOAST_MSG } from "../actions/types";

const initialState = {
  toastMsgs: [],
};

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOAST_MSG:
      return {
        ...state,
        toastMsgs: [
          ...state.toastMsgs,
          { id: action.id, text: action.text, msgtype: action.msgtype },
        ],
      };
    case EMPTY_TOAST_MSG:
      return { ...state, toastMsgs: [] };
    case HIDE_TOAST_MSG:
      return {
        ...state,
        toastMsgs: state.toastMsgs.filter((notification) => {
          return notification.id !== action.id;
        }),
      };
    default:
      return state;
  }
};
export default toastReducer;
