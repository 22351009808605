import { PdfViewer, LoginScreen } from "../Scenes/AuthScreens";
import { HomeScreen } from "../Scenes/Screens";
import UnableToLoadData from "../Scenes/Components/UnableToLoadData";

export const authRoutes = [
  {
    path: "/pdf-menu/:id",
    component: PdfViewer,
  },
  {
    path: "/get_table_details/:id",
    component: HomeScreen,
  },
  {
    path: "/login",
    component: LoginScreen,
  },
  {
    path: "error-page",
    component: UnableToLoadData,
  },
];
