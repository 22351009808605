import React,{forwardRef,useEffect} from "react";
import { userIcon } from "../../../Assets";
import QrReader from "react-qr-reader";

const QrCamera =  forwardRef((props, ref) => {
  return (
    <QrReader
       ref={ref}
      delay={300}
      onError={props.handleError}
      onScan={props.handleScan}
      {...props}
    />
  );
});

export default QrCamera;
