import React, { useCallback, useState } from 'react'
import { userImgIcon, voucherPngIcon } from '../../Assets';
import Header from '../Components/Header';
import Collapsible from 'react-collapsible';
import PromotionDetails from '../Components/PromotionDetails';
import { Tabbar } from '../Components/Tabbar';
import { LoyalityAccountScreen, LoyalityHistoryScreen } from '.';
import Vouchers from './Vouchers';

function Promotions(params) {

    const [activeTab, setActiveTab] = useState(1)

    const switchTab = useCallback((value) => {
        setActiveTab(value)
    }, [activeTab]);

    return (
        <div className="wrapper loyality_ms">
            <Header backTitle={'Back to Order'} />

            <section className="varification-sec">
                <a href="new-items.html" className="cross-icon"></a>
                <div className="row mx-0 px-3 justify-content-start">
                    <div className="circle-img col-2 mx-0">
                        <figure className=""><img src={userImgIcon} alt="" /></figure>
                    </div>
                    <div className="naming-sec col-10">
                        <h4> Harry's Boat Quay </h4>
                        <p className="md-txt h5"> Member #<span className="h3">3456565</span></p>
                    </div>
                </div>
            </section>

            <Tabbar activeTab={activeTab} switchTab={switchTab} />

            {activeTab === 0 ?
                <LoyalityAccountScreen /> :
                activeTab === 1 ? <Vouchers /> :
                    <LoyalityHistoryScreen />
            }


            {/* <section className="info-sec">
                <div className="history-info" id="promotions">
                    <h4 className="title"> Promotions </h4>

                    {[1, 1, 1].map(each => (
                        <PromotionDetails />
                    ))}


                </div>
            </section> */}

        </div>
    )
}
export default Promotions;